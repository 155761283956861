/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


.mat-tab-label.mat-tab-label-active {
    color: #EA3E6F !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #EA3E6F !important;
}

.page {
    padding: 15px;
}

.header {
    display: flex;
    height: 45px;
}

.button-back {
    flex: 10%;
    width: 45px;
    height: 45px;
    border: 1px solid #F0F1F5;
    border-radius: 14px;
}

.button-back .mat-button{
    min-width: 57px;
    padding: 5px 0px;
}

.title-page {
    flex: 90%;
    height: 45px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    padding: 10px 30px 10px 0px;
}

.footer {
    position: absolute;
    left: 7.5%;
    text-align: center;
    height: 40px;
    bottom: 25px;
    width: 85%;
    text-align: center;
}

.content {
    position: absolute;
    top: 60px;
    width: 80%;
    height: 75%;
    overflow-x: hidden;
    padding: 10px;
    padding-left: 5%;
}

.gotch-button {
    margin-bottom: 10px;
    padding: 0.6em 2em;
    border-radius: 20px;
    color: #fff;
    background-color: #EA3E6F;
    font-size: 14px;
    font-weight: 700;
    border: 0;
    cursor: pointer;
    min-width: 75%;
    height: 45px;
}

.gotch-button-disabled {
    margin-bottom: 10px;
    padding: 0.6em 2em;
    border-radius: 20px;
    color: #fff;
    background-color: rgba(234, 62, 111, 0.50);
    font-size: 14px;
    font-weight: 700;
    border: 0;
    cursor: pointer;
    min-width: 75%;
    height: 45px;
}

.mdc-button {
    padding: 8px 10px 0 8px!important;
}

.spot-name {
    position: absolute;
    left: 20px;
    bottom: 20px;
    background: #ffffff;
    border: none !important;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    align-items: center;
    text-align: center;
    color: #3d3d3d;
    padding: 5px;
}

div.btnMap {
    position: absolute;
    top: 10px;
    right: 10px;
}

@media screen and (max-width: 600px) {
    .title-page {
        font-size: 16px;
    }

    .spot-name {
        font-size: 13px;
    }
}